var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { debug } from 'debug';
var DebugAdapter = /** @class */ (function () {
    function DebugAdapter(name, baseName) {
        this.name = name;
        this.baseName = baseName;
        var logger;
        if (baseName) {
            logger = debug(baseName).extend(name, DebugAdapter.SEPARATOR);
        }
        else {
            logger = debug(name);
        }
        this.logger = {
            print: logger,
            debug: logger.extend('debug', DebugAdapter.SEPARATOR),
            error: logger.extend('error', DebugAdapter.SEPARATOR),
            info: logger.extend('info', DebugAdapter.SEPARATOR),
            warning: logger.extend('warning', DebugAdapter.SEPARATOR),
        };
        this.setActive(true);
        if (typeof window !== 'undefined') {
            this.logger.print.color = '#000';
            this.logger.debug.color = '#8bc34a';
            this.logger.error.color = '#f44336';
            this.logger.info.color = '#2196f3';
            this.logger.warning.color = '#ffc107';
        }
        else {
            this.logger.print.color = '6';
            this.logger.debug.color = '10';
            this.logger.error.color = '9';
            this.logger.info.color = '21';
            this.logger.warning.color = '11';
        }
    }
    DebugAdapter.prototype.print = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['print', message], args, false));
        return this;
    };
    DebugAdapter.prototype.debug = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['debug', message], args, false));
        return this;
    };
    DebugAdapter.prototype.error = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['error', message], args, false));
        return this;
    };
    DebugAdapter.prototype.info = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['info', message], args, false));
        return this;
    };
    DebugAdapter.prototype.warning = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['warning', message], args, false));
        return this;
    };
    DebugAdapter.prototype.log = function (logType, message) {
        var _a;
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        (_a = this.logger)[logType].apply(_a, __spreadArray([message], args, false));
        return this;
    };
    DebugAdapter.prototype.setActive = function (state) {
        this.active = state;
        this.logger.print.enabled = state;
        this.logger.debug.enabled = state;
        this.logger.error.enabled = state;
        this.logger.info.enabled = state;
        this.logger.warning.enabled = state;
    };
    DebugAdapter.prototype.isActive = function () {
        return this.active;
    };
    DebugAdapter.SEPARATOR = ':';
    return DebugAdapter;
}());
export { DebugAdapter };
