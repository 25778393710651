var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import RouteRecognizer from 'route-recognizer';
import { Injectable } from '@shared/decorators/Injectable';
import { Log4ts } from '@shared/decorators/Log4ts';
import { LogChannel } from '@shared/lib/log';
var RouterService = /** @class */ (function () {
    function RouterService() {
        this.registry = [];
        this.recognizer = new RouteRecognizer();
    }
    RouterService.prototype.addRoute = function (route) {
        this.registry.push(route);
        return route;
    };
    RouterService.prototype.exec = function (callback) {
        callback();
        return this.run();
    };
    RouterService.prototype.run = function () {
        var _this = this;
        this.log.debug('Run');
        this.registry.forEach(function (route) {
            var options = {
                as: route.name(),
            };
            var routes = [
                {
                    path: route.path(),
                    handler: function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        return route.handle.apply(route, args);
                    },
                },
            ];
            _this.recognizer.add(routes, options);
        });
        return this.consumeRoute();
    };
    RouterService.prototype.consumeRoute = function () {
        var _a;
        var pathName = "".concat(window.location.pathname.replace(/\/$/g, ''), "/");
        var path$ = pathName + window.location.search;
        var result = this.recognizer.recognize(path$);
        var queryParams = (_a = result === null || result === void 0 ? void 0 : result.queryParams) !== null && _a !== void 0 ? _a : {};
        var results = Array.from((result || []));
        results.forEach(function (_a) {
            var handler = _a.handler, params = _a.params;
            if (handler && typeof handler === 'function') {
                handler(params, queryParams);
            }
        });
        return this;
    };
    __decorate([
        Log4ts('RouterService'),
        __metadata("design:type", LogChannel)
    ], RouterService.prototype, "log", void 0);
    RouterService = __decorate([
        Injectable('RouterService'),
        __metadata("design:paramtypes", [])
    ], RouterService);
    return RouterService;
}());
export { RouterService };
