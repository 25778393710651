var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Injectable } from '@shared/decorators/Injectable';
import { LogChannel } from './LogChannel';
import { DebugAdapter } from './adapters';
var Log = /** @class */ (function () {
    function Log() {
    }
    Log_1 = Log;
    /**
     * Create a log channel
     * @example
     * ```
     * // create a channel with the namespace feature
     * const channel = Log.channel('features')
     * ...
     * // create a channel for services:auth namespace
     * const channel = Log.channel(['services', 'auth'])
     * ...
     * // create a channel for services:auth namespace, using custom adapter
     * const channel = Log.channel(['services', 'auth'], ConsoleAdapter)
     * ...
     * // Later in the code
     * channel.info('hi there')
     * ```
     * @param name
     * @param adapter
     */
    Log.channel = function (name, adapter) {
        var name$ = Array.isArray(name) ? name.join(DebugAdapter.SEPARATOR) : name;
        var Adapter = adapter !== null && adapter !== void 0 ? adapter : DebugAdapter;
        if (!Log_1.channels[name$]) {
            Log_1.channels[name$] = new LogChannel(name$, Adapter);
        }
        return Log_1.channels[name$];
    };
    /**
     * Log error method
     * @param message
     * @param args
     */
    Log.error = function (message) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return (_a = Log_1.channel('Global')).error.apply(_a, __spreadArray([message], args, false));
    };
    /**
     * Log warning method
     * @param message
     * @param args
     */
    Log.warning = function (message) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return (_a = Log_1.channel('Global')).warning.apply(_a, __spreadArray([message], args, false));
    };
    /**
     * Log info method
     * @param message
     * @param args
     */
    Log.info = function (message) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return (_a = Log_1.channel('Global')).info.apply(_a, __spreadArray([message], args, false));
    };
    /**
     * Log debug method
     * @param message
     * @param args
     */
    Log.debug = function (message) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return (_a = Log_1.channel('Global')).debug.apply(_a, __spreadArray([message], args, false));
    };
    /**
     * Log message method
     * @param message
     * @param args
     */
    Log.print = function (message) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return (_a = Log_1.channel('Global')).print.apply(_a, __spreadArray([message], args, false));
    };
    Log.canLog = function () {
        if (process.env.NODE_ENV === 'test') {
            if (!process.env.ALLOW_LOGS) {
                return false;
            }
        }
        if (process.env.APPLICATION_TYPE === 'SERVER') {
            return true;
        }
        if (process.env.NODE_ENV !== 'production') {
            return true;
        }
        try {
            return ['1', 'true'].includes(localStorage.getItem('__LOGS__') || '');
        }
        catch (e) {
            // nothing
        }
        return false;
    };
    /**
     * Disable some console
     */
    Log.configure = function () {
        if (this.canLog()) {
            try {
                if (localStorage) {
                    localStorage.setItem('debug', 'app:*');
                }
            }
            catch (e) {
                // nothing
            }
        }
        else {
            console.log = function () { return undefined; };
            console.dir = function () { return undefined; };
            console.warn = function () { return undefined; };
            console.group = function () { return undefined; };
            console.groupCollapsed = function () { return undefined; };
            console.groupEnd = function () { return undefined; };
        }
    };
    var Log_1;
    Log.channels = {};
    Log = Log_1 = __decorate([
        Injectable('Log')
    ], Log);
    return Log;
}());
export { Log };
