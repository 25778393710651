import { route } from './utils/route';
var HomeController = function () { return import('./controllers/HomeController'); };
var FeedController = function () { return import('./controllers/FeedController'); };
var AuthenticationController = function () { return import('./controllers/AuthenticationController'); };
var PopController = function () { return import('./controllers/PopController'); };
var ReferralController = function () { return import('./controllers/ReferralController'); };
// eslint-disable-next-line import/no-anonymous-default-export
export default (function () {
    route('/', [HomeController, 'getHome']).name('home');
    route('/partners', [ReferralController, 'referralView']).name('view');
    route('referral/:code', [ReferralController, 'getReferralHome']).name('referralhome');
    route('/feed')
        .name('feed')
        .group(function (route) {
        route('/', [FeedController, 'feedList']).name('list');
        route('/:id/view', [FeedController, 'feedView']).name('view');
    });
    route('/authentication')
        .name('authentication')
        .group(function (route) {
        route('/', [AuthenticationController, 'autograph']).name('view');
        route('/:id/view', [AuthenticationController, 'authenticationView']).name('authenticationView');
    });
    route('/pop')
        .name('pop')
        .group(function (route) {
        route('/', [PopController, 'popIndex']).name('report');
        route('/categories/:id/', [PopController, 'categoriesReport']).name('categories');
        route('/categories/:id/series/:id', [PopController, 'seriesReport']).name('series');
        route('/categories/:id/series/:id/sets/:id', [PopController, 'setReport']).name('set');
    });
});
