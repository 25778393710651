import { Log } from '../lib/log';
/**
 * @example
 * ```
 * class Foo {
 *      @Log4ts('FooService')
 *      log: LogChannel;
 *
 *      // Auto-discover name of the channel (eg: name will become Foo)
 *      @Log4ts()
 *      log: LogChannel;
 *
 *      // Log channel with name and adapter
 *      @Log4ts('FooService', ConsoleAdapter)
 *      log: LogChannel;
 *
 *      foo () {
 *          this.log.info('hi there');
 *      }
 * }
 * ```
 * @param name
 * @param adapter
 * @constructor
 */
export function Log4ts(name, adapter) {
    return function (target) {
        var _a, _b;
        return ({
            value: Log.channel((_b = (_a = name !== null && name !== void 0 ? name : target.name) !== null && _a !== void 0 ? _a : target.constructor.name) !== null && _b !== void 0 ? _b : 'global', adapter),
        });
    };
}
