var Controller = /** @class */ (function () {
    function Controller() {
        this._beforeOptions = {};
    }
    /**
     * @deprecated Not Implemented Yet.
     * @param method
     * @param options
     */
    Controller.prototype.before = function (method, options) {
        this._before = method;
        this._beforeOptions = options !== null && options !== void 0 ? options : {};
        return this;
    };
    return Controller;
}());
export { Controller };
