var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ConsoleAdapter = /** @class */ (function () {
    function ConsoleAdapter(name, baseName) {
        this.name = name;
        this.baseName = baseName;
        var base = [baseName, name].filter(Boolean).join(':');
        this.logger = {
            print: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return console.log.apply(console, __spreadArray(["".concat(base)], args, false));
            },
            debug: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return console.log.apply(console, __spreadArray(["".concat(base, ":debug")], args, false));
            },
            error: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return console.error.apply(console, __spreadArray(["".concat(base, ":error")], args, false));
            },
            info: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return console.info.apply(console, __spreadArray(["".concat(base, ":info")], args, false));
            },
            warning: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return console.warn.apply(console, __spreadArray(["".concat(base, ":warning")], args, false));
            },
        };
        this.setActive(true);
    }
    ConsoleAdapter.prototype.print = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['print', message], args, false));
        return this;
    };
    ConsoleAdapter.prototype.debug = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['debug', message], args, false));
        return this;
    };
    ConsoleAdapter.prototype.error = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['error', message], args, false));
        return this;
    };
    ConsoleAdapter.prototype.info = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['info', message], args, false));
        return this;
    };
    ConsoleAdapter.prototype.warning = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['warning', message], args, false));
        return this;
    };
    ConsoleAdapter.prototype.log = function (logType, message) {
        var _a;
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        if (this.active) {
            (_a = this.logger)[logType].apply(_a, __spreadArray([message], args, false));
        }
        return this;
    };
    ConsoleAdapter.prototype.setActive = function (state) {
        this.active = state;
    };
    ConsoleAdapter.prototype.isActive = function () {
        return this.active;
    };
    return ConsoleAdapter;
}());
export { ConsoleAdapter };
