import { injectable } from 'inversify';
import { InjectableMetaOptions } from '../constants/ReflectMetadata';
import { DependencyContainer } from '../lib/dependencyInjection/container';
/**
 *
 * @param name
 * @param options
 * @constructor
 */
export function Injectable(name, options) {
    if (options === void 0) { options = {}; }
    return function (target) {
        if (name && typeof name === 'object') {
            options = name;
        }
        if (name && typeof name === 'string') {
            options.name = name;
        }
        if (!options.name) {
            var getInjectableName = target.getInjectableName ||
                target.prototype.getInjectableName;
            var injectableName = getInjectableName ? getInjectableName() : null;
            options.name = injectableName || target.name || target.constructor.name;
        }
        if (!options.symbol) {
            options.symbol = Symbol.for(options.name);
        }
        Reflect.defineMetadata(InjectableMetaOptions, options, target);
        injectable()(target);
        DependencyContainer.bind(options.symbol).to(target);
    };
}
