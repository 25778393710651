var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Injectable } from '@shared/decorators/Injectable';
import { DebugAdapter } from './adapters';
var LogChannel = /** @class */ (function () {
    function LogChannel(name, adapter, baseName) {
        if (adapter === void 0) { adapter = DebugAdapter; }
        if (baseName === void 0) { baseName = ''; }
        this.name = name;
        this.adapter = new adapter(name, baseName);
    }
    /**
     * Channel error method
     * @param message
     * @param args
     */
    LogChannel.prototype.error = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['error', message], args, false));
        return this;
    };
    /**
     * Channel warning method
     * @param message
     * @param args
     */
    LogChannel.prototype.warning = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['warning', message], args, false));
        return this;
    };
    /**
     * Channel info method
     * @param message
     * @param args
     */
    LogChannel.prototype.info = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['info', message], args, false));
        return this;
    };
    /**
     * Channel debug method
     * @param message
     * @param args
     */
    LogChannel.prototype.debug = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['debug', message], args, false));
        return this;
    };
    /**
     * Channel print method
     * @param message
     * @param args
     */
    LogChannel.prototype.print = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.log.apply(this, __spreadArray(['print', message], args, false));
        return this;
    };
    /**
     * Channel log method
     * @param logType
     * @param message
     * @param args
     * @private
     */
    LogChannel.prototype.log = function (logType, message) {
        var _a;
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        if (this.canLog()) {
            (_a = this.adapter)[logType].apply(_a, __spreadArray([message], args, false));
        }
    };
    /**
     * Check if the channel can log
     * @private
     */
    LogChannel.prototype.canLog = function () {
        if (process.env.NODE_ENV !== 'production') {
            return true;
        }
        try {
            if (typeof localStorage !== 'undefined' && localStorage.getItem('__LOGS__') !== null) {
                return true;
            }
        }
        catch (e) {
            // nothing
        }
        return false;
    };
    LogChannel.prototype.setActive = function (state) {
        this.adapter.setActive(state);
        return this;
    };
    LogChannel.prototype.isActive = function () {
        return this.adapter.isActive();
    };
    LogChannel = __decorate([
        Injectable('LogChannel'),
        __metadata("design:paramtypes", [String, Object, Object])
    ], LogChannel);
    return LogChannel;
}());
export { LogChannel };
