var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cleanPath } from '@shared/lib/strings/cleanPath';
import { route } from '../utils/route';
import { Controller } from './Controller';
/**
 * Route class used to create Route definitions used to load partial scripts to the desired
 * page route.
 * The route works similarly to Laravel route, the only difference it's that we have implemented
 * in a frontend way, so we don't support middlewares, methods and the other features.
 */
var Route = /** @class */ (function () {
    function Route(_path, _action, _name) {
        this._path = _path;
        this._action = _action;
        this._name = _name;
    }
    /**
     * Name specify name of the route.
     * @param name
     */
    Route.prototype.name = function (name) {
        if (typeof name !== 'undefined') {
            this._name = "".concat(name !== null && name !== void 0 ? name : '');
            return this;
        }
        if (this._parent) {
            return "".concat(this._parent.name(), ".").concat(this._name);
        }
        return "".concat(this._name);
    };
    /**
     * The path used to match the location path.
     * @param path
     */
    Route.prototype.path = function (path) {
        if (typeof path !== 'undefined') {
            this._path = "".concat(path !== null && path !== void 0 ? path : '');
            return this;
        }
        if (this._parent) {
            return "/".concat(cleanPath(this._parent.path() + '/' + this._path), "/");
        }
        return "/".concat(cleanPath(this._path), "/");
    };
    /**
     * The action we want to do when the route match.
     * @param action
     */
    Route.prototype.action = function (action) {
        if (typeof action !== 'undefined') {
            this._action = action;
            return this;
        }
        return this._action;
    };
    /**
     * parent route used to define relative routes.
     * @param parent
     */
    Route.prototype.parent = function (parent) {
        if (typeof parent !== 'undefined') {
            this._parent = parent;
            return this;
        }
        return this._parent;
    };
    /**
     * Create a group of the defined route.
     * @param callback
     */
    Route.prototype.group = function (callback) {
        var _this = this;
        callback(function (path, action) {
            return route(path, action).parent(_this);
        });
        return this;
    };
    /**
     * Route handler, executed when the route match.
     * @param args
     */
    Route.prototype.handle = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var action, actionSlice, _a, _b, _c, _d, controller, method, instance;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        action = this._action;
                        actionSlice = [action];
                        if (!!Array.isArray(action)) return [3 /*break*/, 3];
                        if (!this.isActionCallback(action)) return [3 /*break*/, 2];
                        _a = actionSlice;
                        _b = 0;
                        return [4 /*yield*/, action()];
                    case 1:
                        _a[_b] = _e.sent();
                        _e.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        actionSlice[0] = action[0];
                        actionSlice[1] = action[1];
                        _e.label = 4;
                    case 4:
                        if (!this.isActionCallback(actionSlice[0])) return [3 /*break*/, 6];
                        _c = actionSlice;
                        _d = 0;
                        return [4 /*yield*/, actionSlice[0]()];
                    case 5:
                        _c[_d] = _e.sent();
                        _e.label = 6;
                    case 6:
                        controller = actionSlice[0].default || actionSlice[0];
                        method = actionSlice[1] || 'invoke';
                        instance = new controller();
                        if (!instance.setup) return [3 /*break*/, 8];
                        return [4 /*yield*/, instance.setup.apply(instance, __spreadArray([method], args, false))];
                    case 7:
                        _e.sent();
                        _e.label = 8;
                    case 8:
                        instance[method].apply(instance, args);
                        return [2 /*return*/];
                }
            });
        });
    };
    Route.prototype.isActionCallback = function (action) {
        if (typeof action === 'function') {
            return !(action.prototype instanceof Controller);
        }
        return false;
    };
    return Route;
}());
export { Route };
